import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import RptSingleTotal from './views/RptSingleTotal';
import { Collapse } from 'bootstrap';

import { errorMessage, onlyUnique, deptName, rptTotal, rptTotalQty } from '../functions';

const mapStateToProps = (state) => {
    return {
        apiServer: state.apiServer,
        agent: state.agent,
        uid: state.uid,
        session: state.session,
        periodType: state.periodType,
        periodDateStart: state.periodDateStart,
        periodDateEnd: state.periodDateEnd
    }
}

const mapDispatchToProps = { errorMessage }

function RptHourlySingleTotal(props) {
	const [rpt_hou, setRptHou] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const refresh = () => {
        setIsLoading(true);

        var F1031 = props.periodType;
        var F254 = props.periodDateStart;

        // logging needed

        fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_hou&agent='+props.agent+'&F1031='+F1031+'&F254='+F254+'&F1034=3')
        .then(response => response.json())
        .then(result => {
            // logging needed
            if(result.data){                
                setRptHou(result.data);
                setIsLoading(false);
                let collapsible = document.getElementById('collapse_HourlySingleTotal');
                if(!collapsible.classList.contains("show")){            
                    new Collapse(document.getElementById('collapse_HourlySingleTotal'));
                }
            }
            else{
                props.errorMessage("Error retrieving rpt_hou data: "+result.message);
                setIsLoading(false);
            }
        });
    }

    let dataArr = [];
    rpt_hou.forEach((record,index,arr) => {
        let res = arr.filter(item => record.F01 === item.F01);
        
        if (res.length >=2){
            let qtyT = res.reduce((prev,cur) => {
                return prev + cur.F64;                                                
            },0);
            let amtT = res.reduce((prev,cur) => {
                return prev + cur.F65;                                                
            },0);
            let test = dataArr.filter(item => item.F01 === record.F01);
            if (test.length === 0){
                dataArr.push({...record,...{F64:qtyT,F65:amtT}});   
            }            
        }else{
            dataArr.push(record)
        }
        
        
    })

    let rptData = dataArr.map(record => {
        return ({
            description: record.F01 + ":00",
            qty: record.F64,
            dollars: record.F65
        })
    })

    
	return (
		<ReportTab id='HourlySingleTotal'>
			<ReportTab.Header parentId='HourlySingleTotal' title='Hourly Single Total'>
				<BtnRefresh parentId='HourlySingleTotal' loading={isLoading} action={refresh} />
				<BtnExpand parentId='HourlySingleTotal' action={refresh} />
			</ReportTab.Header>
			<ReportTab.Expand parentId='HourlySingleTotal'>
				<RptSingleTotal title='Hourly Net Sales' description='Hour' data={rptData} />
			</ReportTab.Expand>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptHourlySingleTotal);

