import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import RptMultiTotal from './views/RptMultiTotal';

import { errorMessage, onlyUnique, cmp, tlzName, deptName, rptTotalQty, rptTotal } from '../functions';

const mapStateToProps = (state) => {
    return {
        apiServer: state.apiServer,
        agent: state.agent,
        uid: state.uid,
        session: state.session,
        periodType: state.periodType,
        periodDateStart: state.periodDateStart,
        periodDateEnd: state.periodDateEnd,
        dept_tab: state.dept_tab,
        tlz_tab: state.tlz_tab
    }
}

const mapDispatchToProps = { errorMessage }

function RptHourlytMultiTotal(props){
	const [rpt_hou, setRptHou] = useState([]);

        const refresh = () => {
            
           
            var F1031 = props.periodType;
            var F254 = props.periodDateStart;

            // logging needed

            fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_hou&agent='+props.agent+'&F1031='+F1031+'&F254='+F254+'&F1034=3')
            .then(response => response.json())
            .then(result => {
                // logging needed
                if(result.data){
                    debugger;
                    setRptHou(result.data);
                }
                else{
                    props.errorMessage("Error retrieving rpt_hou data: "+result.message);
                }
            });
        };

	// FORMAT REPORT DATA FOR MULTI TOTAL REPORT
	var rptData = rpt_hou.map((record) => record.F03).filter(onlyUnique).sort().map((deptNo) => {
		return ({
			description: deptNo + ' ' + deptName(rpt_hou, deptNo),
			data: rpt_hou.filter((data) => data.F03 === deptNo).sort(function (a, b) {
				return cmp(a.F1179, b.F1179) || cmp(a.F1034, b.F1034);
			}).map((record) => record.F1034).filter(onlyUnique).map((totalizer) => {
				return ({
					code: totalizer,
					description: tlzName(rpt_hou, totalizer),
					qty: rptTotalQty(rpt_hou.filter((rpt) => rpt.F03 === deptNo), totalizer),
					dollars: rptTotal(rpt_hou.filter((rpt) => rpt.F03 === deptNo), totalizer)
				})
			})
		});
	});
    debugger;
	return (
		<ReportTab id='DepartmentMultiTotals'>
			<ReportTab.Header parentId='DepartmentMultiTotals' title='Hourly Single Totals'>
				<BtnRefresh parentId='DepartmentMultiTotals' action={refresh} />
				<BtnExpand parentId='DepartmentMultiTotals' action={refresh} />
			</ReportTab.Header>
			<ReportTab.Expand parentId='DepartmentMultiTotals'>
				<RptMultiTotal data={rptData} />
			</ReportTab.Expand>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptHourlytMultiTotal);
