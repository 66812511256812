import { tlzName, onlyUnique, rptTotal, rptTotalTlzRange } from '../../functions';

function RptSafeInformation(props) {
    let numLoans = props.data.filter(rpt => (rpt.F1034 >= 1000 && rpt.F1034 < 1099)).length;
    let numPickups = props.data.filter(rpt => (rpt.F1034 >= 1100 && rpt.F1034 < 1199)).length;

   
    if((numLoans === 0) && (numPickups === 0)){
        return null;
    }


    const totalSafeStart = () => {
		return rptTotalTlzRange(props.data,2000,2099);
	};

	const totalPickups = () => {
		return rptTotalTlzRange(props.data,1100,1199);
	};

	const totalLoans = () => {
		return rptTotalTlzRange(props.data,1000,1099);
	};

	const totalReceived = () => {
		return rptTotalTlzRange(props.data,2100,2199);
	};

	const totalDeposit = () => {
		return rptTotalTlzRange(props.data,2200,2299);
	};

	const totalSafeShortOver = () => {
		return rptTotalTlzRange(props.data,2500,2599);
	};

	const totalSafeEnding = () => {
		return rptTotalTlzRange(props.data,2400,2499);
	};    

	
	let safe_start = props.data.filter(rpt => rpt.F1034 >= 2000 && rpt.F1034 < 2099).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
				<div key={'safe_start_'+index} className='row'>
					<div className='col-8'>{tlzName(props.data, record-1900)}</div>
					<div className='col-4'>{rptTotal(props.data.filter(rpt => rpt.F1034 >= 2000 && rpt.F1034 < 2099), record).toFixed(2)}</div>
				</div>
	));    

	
	let pickups = props.data.filter(rpt => rpt.F1034 >= 1100 && rpt.F1034 < 1199).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
				<div key={'pickups_'+index} className='row'>
					<div className='col-8'>{tlzName(props.data, record-1000)}</div>
					<div className='col-4'>{rptTotal(props.data.filter(rpt => rpt.F1034 >= 1100 && rpt.F1034 < 1199), record).toFixed(2)}</div>
				</div>
	));

	
	let loans = props.data.filter(rpt => rpt.F1034 >= 1000 && rpt.F1034 < 1099).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
				<div key={'loans_'+index} className='row'>
					<div className='col-8'>{tlzName(props.data, record-900)}</div>
					<div className='col-4'>{rptTotal(props.data.filter(rpt => rpt.F1034 >= 1000 && rpt.F1034 < 1099), record).toFixed(2)}</div>
				</div>
	));

	
	let received = props.data.filter(rpt => rpt.F1034 >= 2100 && rpt.F1034 < 2199).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
				<div key={'received_'+index} className='row'>
					<div className='col-8'>{tlzName(props.data, record-2000)}</div>
					<div className='col-4'>{rptTotal(props.data.filter(rpt => rpt.F1034 >= 2100 && rpt.F1034 < 2199), record).toFixed(2)}</div>
				</div>
	));

	
	let deposit = props.data.filter(rpt => rpt.F1034 >= 2200 && rpt.F1034 < 2299).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
				<div key={'deposit_'+index} className='row'>
					<div className='col-8'>{tlzName(props.data, record-2100)}</div>
					<div className='col-4'>{rptTotal(props.data.filter(rpt => rpt.F1034 >= 2200 && rpt.F1034 < 2299), record).toFixed(2)}</div>
				</div>
	));

	
	let safe_shortover = props.data.filter(rpt => rpt.F1034 >= 2500 && rpt.F1034 < 2599).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
				<div key={'safe_shortover_'+index} className='row'>
					<div className='col-8'>{tlzName(props.data, record-2400)}</div>
					<div className='col-4'>{rptTotal(props.data.filter(rpt => rpt.F1034 >= 2500 && rpt.F1034 < 2599), record).toFixed(2)}</div>
				</div>
	));

	
	let safe_ending = props.data.filter(rpt => rpt.F1034 >= 2400 && rpt.F1034 < 2499).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
				<div key={'safe_ending_'+index} className='row'>
					<div className='col-8'>{tlzName(props.data, record-2300)}</div>
					<div className='col-4'>{rptTotal(props.data.filter(rpt => rpt.F1034 >= 2400 && rpt.F1034 < 2499), record).toFixed(2)}</div>
				</div>
	));

    return (
        <>
            <div className='row bg-secondary text-white'>
                <div className='col-12'><b>Safe Information</b></div>
            </div>
            <div className='row'>
                <div className='col-8'><b>Safe Start</b></div>
                <div className='col-4'>&nbsp;</div>
            </div>
            {safe_start}
            <div className='row'>
                <div className='col-4'>&nbsp;</div>
                <div className='col-4 text-right pr-0'><b>Total</b></div>
                <div className='col-4'><b>{totalSafeStart().toFixed(2)}</b></div>
            </div>



            <hr className='m-1 p-0' />
            <div className='row'>
                <div className='col-8'><b>Pickups</b></div>
                <div className='col-4'>&nbsp;</div>
            </div>
            {pickups}
            <div className='row'>
                <div className='col-4'>&nbsp;</div>
                <div className='col-4 text-right pr-0'><b>Total</b></div>
                <div className='col-4'><b>{totalPickups().toFixed(2)}</b></div>
            </div>



            <hr className='m-1 p-0' />
            <div className='row'>
                <div className='col-8'><b>Loans</b></div>
                <div className='col-4'>&nbsp;</div>
            </div>
            {loans}
            <div className='row'>
                <div className='col-4'>&nbsp;</div>
                <div className='col-4 text-right pr-0'><b>Total</b></div>
                <div className='col-4'><b>{totalLoans().toFixed(2)}</b></div>
            </div>



            <hr className='m-1 p-0' />
            <div className='row'>
                <div className='col-8'><b>Received</b></div>
                <div className='col-4'>&nbsp;</div>
            </div>
            {received}
            <div className='row'>
                <div className='col-4'>&nbsp;</div>
                <div className='col-4 text-right pr-0'><b>Total</b></div>
                <div className='col-4'><b>{totalReceived().toFixed(2)}</b></div>
            </div>



            <hr className='m-1 p-0' />
            <div className='row'>
                <div className='col-8'><b>Deposit</b></div>
                <div className='col-4'>&nbsp;</div>
            </div>
            {deposit}
            <div className='row'>
                <div className='col-4'>&nbsp;</div>
                <div className='col-4 text-right pr-0'><b>Total</b></div>
                <div className='col-4'><b>{totalDeposit().toFixed(2)}</b></div>
            </div>



            <hr className='m-1 p-0' />
            <div className='row'>
                <div className='col-8'><b>Short / Over</b></div>
                <div className='col-4'>&nbsp;</div>
            </div>
            {safe_shortover}
            <div className='row'>
                <div className='col-4'>&nbsp;</div>
                <div className='col-4 text-right pr-0'><b>Total</b></div>
                <div className='col-4'><b>{totalSafeShortOver().toFixed(2)}</b></div>
            </div>



            <hr className='m-1 p-0' />
            <div className='row'>
                <div className='col-8'><b>Safe Ending</b></div>
                <div className='col-4'>&nbsp;</div>
            </div>
            {safe_ending}
            <div className='row'>
                <div className='col-4'>&nbsp;</div>
                <div className='col-4 text-right pr-0'><b>Total</b></div>
                <div className='col-4'><b>{totalSafeEnding().toFixed(2)}</b></div>
            </div>
        </>
    );
}

export default RptSafeInformation;