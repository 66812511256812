import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import RptMultiTotal from './views/RptMultiTotal';
import { Collapse } from 'bootstrap';

import { errorMessage, onlyUnique, cmp, tlzName, tlzSectionName, rptTotal, rptTotalQty } from '../functions';

const mapStateToProps = (state) => {
        return {
		apiServer: state.apiServer,
		agent: state.agent,
		uid: state.uid,
		session: state.session,
        periodType: state.periodType,
        periodDateStart: state.periodDateStart,
        periodDateEnd: state.periodDateEnd,
        tlz_tab: state.tlz_tab
    }
}

const mapDispatchToProps = { errorMessage }

function RptStoreMultiTotal(props){
	const [rpt_fin, setRptFin] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    const refresh = () => {
        setIsLoading(true);

        var F1031 = props.periodType;
        var F254 = props.periodDateStart;

            // logging needed
        fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_fin&agent='+props.agent+'&F1031='+F1031+'&F254='+F254)
        .then(response => response.json())
        .then(result => {
                    // logging needed
            if(result.data){                                
                setRptFin(result.data);
                setIsLoading(false);
                let collapsible = document.getElementById('collapse_StoreMultiTotals');
                if(!collapsible.classList.contains("show")){
                    new Collapse(document.getElementById('collapse_StoreMultiTotals'));
                }
            }
            else{
                props.errorMessage("Error retrieving rpt_fin data: "+result.message);
                setIsLoading(false);
            }
        });
};

	// FORMAT REPORT DATA FOR MULTI TOTAL REPORT
	var rptData = rpt_fin.map((record) => record.F1179).filter(onlyUnique).sort().map((section) => {
		return ({
			description: tlzSectionName(rpt_fin, section),
			data: rpt_fin.filter((data) => data.F1179 === section).sort(function (a, b) {
				return cmp(a.F1147,b.F1147) || cmp(a.F1034,b.F1034)
			}).map((record) => record.F1034).filter(onlyUnique).map((totalizer) => {
				return ({
					code: totalizer,
					description: tlzName(rpt_fin, totalizer),
					qty: rptTotalQty(rpt_fin, totalizer),
					dollars: rptTotal(rpt_fin, totalizer)
				});
			})
		});
	});
	

	return(
		<ReportTab id='StoreMultiTotals'>
			<ReportTab.Header parentId='StoreMultiTotals' title='Store Multi Totals'>
				<BtnRefresh parentId='StoreMultiTotals' loading={isLoading} action={refresh} />
				<BtnExpand parentId='StoreMultiTotals' action={refresh} />
			</ReportTab.Header>
			<ReportTab.Expand parentId='StoreMultiTotals'>
				<RptMultiTotal data={rptData} /> 
			</ReportTab.Expand>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptStoreMultiTotal);

