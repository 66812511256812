import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import RptMultiTotal from './views/RptMultiTotal';
import { Collapse } from 'bootstrap';

import { errorMessage, rpcName, tlzName, onlyUnique, cmp, rptTotal, rptTotalQty } from '../functions';

const mapStateToProps = (state) => {
        return {
		apiServer: state.apiServer,
		agent: state.agent,
		uid: state.uid,
		session: state.session,
        periodType: state.periodType,
        periodDateStart: state.periodDateStart,
        periodDateEnd: state.periodDateEnd
    }
}

const mapDispatchToProps = { errorMessage }

function RptOthersReportCodeMultiTotal(props){
	const [rpt_rpc, setRptRpc] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

	const refresh = () => {
		setIsLoading(true);

		var F1031 = props.periodType;
		var F254 = props.periodDateStart;

		
		fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_rpc&agent='+props.agent+'&F1031='+F1031+'&F254='+F254)
		.then(response => response.json())
		.then(result => {
	
			if(result.data){
                setRptRpc(result.data);
                setIsLoading(false);
                let collapsible = document.getElementById('collapse_ReportCodeMultiTotals');
                if(!collapsible.classList.contains("show")){            
                    new Collapse(document.getElementById('collapse_ReportCodeMultiTotals'));
                }
			}
			else{
				props.errorMessage("Error retrieving rpt_rpc data: "+result.message);
                setIsLoading(false);
			}
		});
	}

	// FORMAT REPORT DATA FOR MULTI TOTAL REPORT
	var rptData = rpt_rpc.map((record) => record.F18).filter(onlyUnique).sort().map((catNo) => {
		return ({
			description: catNo + ' ' + rpcName(rpt_rpc, catNo),
			data: rpt_rpc.filter((data) => data.F18 === catNo).sort(function (a, b) {
				return cmp(a.F1179, b.F1179) || cmp(a.F1034, b.F1034);
			}).map((record) => record.F1034).filter(onlyUnique).map((totalizer) => {
				return ({
					code: totalizer,
					description: tlzName(rpt_rpc, totalizer),
					qty: rptTotalQty(rpt_rpc.filter((rpt) => rpt.F18 === catNo), totalizer),
					dollars: rptTotal(rpt_rpc.filter((rpt) => rpt.F18 === catNo), totalizer)
				})
			})
		});
	});
	

	return (
		<ReportTab id='ReportCodeMultiTotals'>
			<ReportTab.Header parentId='ReportCodeMultiTotals' title='Report Code Multi Totals'>
				<BtnRefresh parentId='ReportCodeMultiTotals' loading={isLoading} action={refresh} />
				<BtnExpand parentId='ReportCodeMultiTotals' action={refresh} />
			</ReportTab.Header>
			<ReportTab.Expand parentId='ReportCodeMultiTotals'>
				<RptMultiTotal data={rptData} />
			</ReportTab.Expand>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptOthersReportCodeMultiTotal);

