import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from './ReportTab';
import LocationList from './LocationList';

import { onlyUnique } from './functions';

const mapStateToProps = (state) => {
	return {
		version: state.version,
		apiServer: state.apiServer,
		uid: state.uid,
		session: state.session
	}
}

const mapDispatchToProps = { }

function StoreList(props){
	const [stores, setStores] = useState([]);
	const [store, setStore] = useState('');

    useEffect(() => {
        fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=list_agents')
        .then(response => response.json())
        .then(result => {                    
            setStores(result.data);
        });
    }, [props.apiServer, props.session, props.uid]);

	if(store){
		return (<LocationList stores={stores} store={store} />);
	}

	if(stores.map(record => record.store).filter(onlyUnique).length === 1){
		setStore(stores.map(record => record.store)[0]);
	}

	const storeList = () => {
		if(stores.length > 0){
			let store_names = stores.map(record => record.store);
			return (
				<div className='m-0 p-1 d-grid gap-2'>
					{store_names.filter(onlyUnique).map((store_name,index) => <button key={'store_'+index} onClick={() => setStore(store_name)} className='btn btn-block btn-primary'>{store_name}</button>)}
				</div>
			);
		}

		return (
                	<div className='d-flex justify-content-center'>
        	                <b>No stores found</b>
	                </div>
		);
	}

	return (
		<ReportTab>
			 <ReportTab.Header title='Stores'></ReportTab.Header>
			 <ReportTab.AlwaysShow>{storeList()}</ReportTab.AlwaysShow>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(StoreList);
