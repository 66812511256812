import React from 'react';
import ErrorBoundry from './ErrorBoundry';
import 'bootstrap/dist/css/bootstrap.min.css';

function ReportTab(props){
	return (
		<div id={'accordion_'+props.id} className='card bg-light mt-1 mx-1 mb-0 rounded border border-dark shadow' >
			{props.children}
		</div>
	);
}

const Header = (props) => {
	return (
		<div className='card-header m-0 pt-1 pb-1 ps-2 pe-1 theme-primary' id={'heading_'+props.parentId}>
			<div className='d-flex flex-row'>
				<div className='d-flex flex-fill justify-content-start m-auto'><h5 className='m-0'>{props.title}</h5></div>
				<div className='d-flex flex-fill justify-content-end'>
					{props.children}
				</div>
			</div>
		</div>		
	);
}
ReportTab.Header = Header;

const AlwaysShow = (props) => {
	return (
		<div className='my-1 mx-0 p-0'>
			{props.children}
		</div>
	);
}
ReportTab.AlwaysShow = AlwaysShow;

const Expand = (props) => {
	return (
		<div id={'collapse_'+props.parentId} className={props.expanded ? 'mt-0 mb-2 mx-0 p-0 collapse show' : 'mt-0 mb-2 mx-0 p-0 collapse'} aria-labelledby={'heading_'+props.parentId} data-parent={'#accordion_'+props.parentId}>
			<ErrorBoundry>{props.children}</ErrorBoundry>
		</div>
	);
}
ReportTab.Expand = Expand;

export default ReportTab;


