import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import RptSingleTotal from './views/RptSingleTotal';
import { Collapse } from 'bootstrap';

import { errorMessage, onlyUnique, sdpName, rptTotal, rptTotalQty } from '../functions';

const mapStateToProps = (state) => {
        return {
		apiServer: state.apiServer,
		agent: state.agent,
		uid: state.uid,
		session: state.session,
        periodType: state.periodType,
        periodDateStart: state.periodDateStart,
        periodDateEnd: state.periodDateEnd
    }
}

const mapDispatchToProps = { errorMessage }

function RptSubDeptSingleTotal(props) {
	const [rpt_sub, setRptSub] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

	const refresh = () => {
		setIsLoading(true);

		var F1031 = props.periodType;
		var F254 = props.periodDateStart;

		// logging needed
		fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_sub&agent='+props.agent+'&F1031='+F1031+'&F254='+F254+'&F1034=3')
		.then(response => response.json())
		.then(result => {
			// logging needed
			if(result.data){                
				setRptSub(result.data);
                setIsLoading(false);
                let collapsible = document.getElementById('collapse_SubDeptSingleTotal');
                if(!collapsible.classList.contains("show")){            
                    new Collapse(document.getElementById('collapse_SubDeptSingleTotal'));
                }
			}
			else{
				props.errorMessage("Error retrieving rpt_sub data: "+result.message);
                setIsLoading(false);
			}
		});
	}

	let rptData = rpt_sub.map((record) => record.F04).filter(onlyUnique).sort().map((sdpNo) => {
		return ({
			code: sdpNo,
			description: sdpName(rpt_sub, sdpNo),
			qty: rptTotalQty(rpt_sub.filter((rpt) => rpt.F04 === sdpNo), 3),
			dollars: rptTotal(rpt_sub.filter((rpt) => rpt.F04 === sdpNo), 3)
		});
	});

	return (
		<ReportTab id='SubDeptSingleTotal'>
			<ReportTab.Header parentId='SubDeptSingleTotal' title='Sub-dept. Single Total'>
				<BtnRefresh parentId='SubDeptSingleTotal' loading={isLoading} action={refresh} />
				<BtnExpand parentId='SubDeptSingleTotal' action={refresh} />
			</ReportTab.Header>
			<ReportTab.Expand parentId='SubDeptSingleTotal'>
				<RptSingleTotal title='Sub-dept. Net Sales' description='Sub-dept.' data={rptData} />
			</ReportTab.Expand>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptSubDeptSingleTotal);

