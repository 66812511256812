import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Line,Bar } from 'react-chartjs-2';

import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import StoreInformation from './views/StoreInformation';
import QuickViewStats from './views/QuickViewStats';

import { errorMessage, rptTotal } from '../functions';

const mapStateToProps = (state) => {
	return {
		apiServer: state.apiServer,
		agent: state.agent,
		weekstart: state.weekstart,
		uid: state.uid,
		session: state.session,
		periodType: state.periodType,
		periodDateStart: state.periodDateStart,
		periodDateEnd: state.periodDateEnd,
        lastWeekColor: state.lastWeekColor,
        thisWeekColor: state.thisWeekColor
	}
}

const mapDispatchToProps = { errorMessage }

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	Title,
	Tooltip,
	Legend
);

function RptQuickView(props) {
	const [qs_rpt_fin, setQsRptFin] = useState();
    const [qs_rpt_clt_d, setQsRptCltD] = useState();
	const [thisWeek, setThisWeek] = useState(0);
	const [lastWeek, setLastWeek] = useState(0);
	const [dataDetails, setDataDetails] = useState([]);

	const [weekstart] = useState(props.weekstart);
    


	const refresh = useCallback(() => {
		// GET THIS WEEK TOTAL
		let today = new Date();
		let endOfThisWeek = new Date(today.setDate(today.getDate() + 6 - today.getDay() + weekstart));
		let thisWeekF254 = String(endOfThisWeek.getMonth() + 1).padStart(2, '0') + '/' + String(endOfThisWeek.getDate()).padStart(2, '0') + '/' + endOfThisWeek.getFullYear();
		// logging needed

		fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_fin&agent='+props.agent+'&F1031=W&F1034=3&F254='+thisWeekF254)
		.then(response => response.json())
		.then(result => {
			// logging needed
			if(result.data){
				setThisWeek(rptTotal(result.data, 3));
			}
			else{
				props.errorMessage("Error retrieving rpt_fin data: "+result.message);
			}
		});


		// GET LAST WEEK TOTAL
		today = new Date();
		let endOfLastWeek = new Date(today.setDate(today.getDate() + 6 - today.getDay() + weekstart - 7));
		let lastWeekF254 = String(endOfLastWeek.getMonth() + 1).padStart(2, '0') + '/' + String(endOfLastWeek.getDate()).padStart(2, '0') + '/' + endOfLastWeek.getFullYear();
		// logging needed

		fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_fin&agent='+props.agent+'&F1031=W&F1034=3&F254='+lastWeekF254)
		.then(response => response.json())
		.then(result => {
				// logging needed
				if(result.data){
						setLastWeek(rptTotal(result.data, 3));
				}
				else{
						props.errorMessage("Error retrieving rpt_fin data: "+result.message);
				}
		});

		// // GET THIS WEEK / LAST WEEK DETAILS
		
		today = new Date();
		let lwStart = new Date(today.setDate(today.getDate() + 6 - today.getDay() + weekstart - 14));
		let lwStartF254 = String(lwStart.getMonth() + 1).padStart(2, '0') + '/' + String(lwStart.getDate()).padStart(2, '0') + '/' + lwStart.getFullYear();
		today = new Date();
		let twEnd = new Date(today.setDate(today.getDate() + 6 - today.getDay() + weekstart));
		let twEndF254 = String(twEnd.getMonth() + 1).padStart(2, '0') + '/' + String(twEnd.getDate()).padStart(2, '0') + '/' + twEnd.getFullYear();
		

		fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_fin&agent='+props.agent+'&F1031=D&F1034=3&D254='+lwStartF254+'&F254='+twEndF254)
		.then(response => response.json())
		.then(result => {
				// logging needed
				if(result.data){
					
					setDataDetails(result.data);
				}
				else{
					props.errorMessage("Error retrieving rpt_fin data: "+result.message);
				}
		});	


        let qs_today = new Date();
        let qs_todayDate = String(qs_today.getMonth() + 1).padStart(2, '0') + '/' + String(qs_today.getDate()).padStart(2, '0') + '/' + qs_today.getFullYear();

        var qs_F1031 ='D';
        var qs_F254 = qs_todayDate;

        

        fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_fin&agent='+props.agent+'&F1031='+qs_F1031+'&F254='+qs_F254)
        .then(response => response.json())
        .then(result => {
            
            if(result.data){
                setQsRptFin(result.data);
            }
            else{
                props.errorMessage("Error retrieving qs_rpt_fin data: "+result.message);
            }
        });
        // rpt_clt_d


       
        
        fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_clt_d&agent='+props.agent+'&F254='+qs_F254+'&F1034=2')
        .then(response => response.json())
        .then(result => {
           
            
            if(result.data){
                setQsRptCltD(result.data);
            }
            else{
                props.errorMessage("Error retrieving qs_rpt_clt_d data: "+result.message);
            }
        });
	}, [props, weekstart]);

	useEffect(() => {
		
		refresh();
	}, [refresh]);

	let labels = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
	labels = labels.slice(weekstart).concat(labels.slice(0, weekstart));

	let line_options = {
		responsive: true,
		maintainAspectRatio: false,
	  	plugins: {
	    		legend: {
	      			position: 'bottom',
                    labels: {
                        usePointStyle: true,
                        pointStyle: 'rect',
                    },
	    		},
	    		title: {
	      			display: true,
	      			text: ['Net Sales','This Week vs Last Week'],
                    font: {
                        size: 18,
                    }
    			},
	  	},
	};

	const dayTotal = (day) => {
		
		let today = new Date();
		let jsDate = new Date(today.setDate(today.getDate() + 6 - today.getDay() + weekstart - (14 - day)));		
		let DayToFilter = jsDate.getFullYear() + '-' + String(jsDate.getMonth() + 1).padStart(2, '0') + '-' + String(jsDate.getDate()).padStart(2, '0') + 'T00:00:00' ;
		
		let total = rptTotal(dataDetails.filter(record => record.F254 === DayToFilter), 3);
		
		return total;
	}


	let day1 = dayTotal(1);
	let day2 = dayTotal(2);
	let day3 = dayTotal(3);
	let day4 = dayTotal(4);
	let day5 = dayTotal(5);
	let day6 = dayTotal(6);
	let day7 = dayTotal(7);
	let day8 = dayTotal(8);
	let day9 = dayTotal(9);
	let day10 = dayTotal(10);
	let day11 = dayTotal(11);
	let day12 = dayTotal(12);
	let day13 = dayTotal(13);
	let day14 = dayTotal(14);

	let lastWeekData = [day1,day2,day3,day4,day5,day6,day7];
	let thisWeekData = [day8,day9,day10,day11,day12,day13,day14];

	let today = new Date();
	thisWeekData = thisWeekData.slice(0, today.getDay()-weekstart+1);

	let line_data = {
		labels,
		datasets: [
			{
				label: 'This week',
				data: thisWeekData,
				borderWidth: 4,
				pointRadius: 5,
				borderColor: props.thisWeekColor,
				// borderColor: 'rgb(25, 135, 84)',				
				backgroundColor: props.thisWeekColor,
				// backgroundColor: 'rgba(25, 135, 84, 0.5)',
			},
			{
				label: 'Last week',
				data: lastWeekData,
				borderDash: [5, 5],
				borderWidth: 3,
				pointRadius: 5,				
				borderColor: props.lastWeekColor,
				// borderColor: 'rgb(87, 138, 200)',
				backgroundColor: props.lastWeekColor,
				// backgroundColor: 'rgba(87, 138, 200, 0.5)',
			},
	  	],
	};

	let bar_options = {
		indexAxis: 'y',
	  	elements: {
	    		bar: {
	      			borderWidth: 2,
	    		},
	  	},
	  	responsive: true,
	  	plugins: {
	    		legend: {
				display: false,
	      			position: 'bottom',
				labels: {
					usePointStyle: true,
					pointStyle: 'rect',
				},
	    		},
	    		title: {
	      			display: true,
	      			text: ['Total Net Sales','This Week vs Last Week'],
				font: {
					size: 18,
				}
	    		}
	  	},
		scales: {
			y: {
				display: false
			}
		}
	};

	let bar_labels = ['Total'];

	let bar_data = {
		labels: bar_labels,
	  	datasets: [
    			{
	      			label: 'This week',
      				data: [thisWeek.toFixed(2)],
	      			borderColor: props.thisWeekColor,      				
      				backgroundColor: `${props.thisWeekColor}80`,
      				// backgroundColor: 'rgba(25, 135, 84, 0.5)',
	    		},
    			{
	      			label: 'Last week',
      				data: [lastWeek.toFixed(2)],
      				borderColor: props.lastWeekColor,      				
      				backgroundColor: `${props.lastWeekColor}80`,
      				// backgroundColor: 'rgba(87, 138, 200, 0.5)',
	    		},
  		],
	};

	
	return (
		<ReportTab id='QuickView'>
			<ReportTab.Header parentId='QuickView' title='Quick View'>
				<BtnRefresh parentId='QuickView' action={refresh} />
				<BtnExpand expanded={true} parentId='QuickView' action={refresh} />
			</ReportTab.Header>
			<ReportTab.Expand expanded={true} parentId='QuickView'>
				<StoreInformation store='test store' location='test location' />
				<QuickViewStats data={qs_rpt_fin} />
				<Line id='quickViewLineChart' options={line_options} data={line_data} />
				<Bar options={bar_options} data={bar_data} />
				<div className='d-flex justify-content-center fw-bold' style={{color: props.thisWeekColor}}>This week: ${thisWeek.toLocaleString('en-US', {minimumFractionDigits: 2})}</div>
				<div className='d-flex justify-content-center fw-bold' style={{color: props.lastWeekColor}}>Last week: ${lastWeek.toLocaleString('en-US', {minimumFractionDigits: 2})}</div>
			</ReportTab.Expand>
		</ReportTab>
	);
}


export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptQuickView);
