import React from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from './ReportTab';
import BtnExpand from './buttons/BtnExpand';
import { setPeriod, formatDate, setPeriodDateStart,setPeriodDateEnd, formatDateFromInput } from './functions';

const mapStateToProps = (state) => {
        return {
			period: state.period,
			periodDateStart: state.periodDateStart,
            periodDateEnd: state.periodDateEnd
        }
}

const mapDispatchToProps = { setPeriod, setPeriodDateStart,setPeriodDateEnd }

function ReportWizard(props){
	var btnToday = 'btn-primary';
	var btnYesterday = 'btn-primary';
	var btnWeek = 'btn-primary';
	var btnMonth = 'btn-primary';
	var btnYear = 'btn-primary';

	var btnLastYearToday = 'btn-primary';
	var btnLastYearYesterday = 'btn-primary';
	var btnLastYearWeek = 'btn-primary';
	var btnLastYearMonth = 'btn-primary';
	var btnLastYearYear = 'btn-primary';

	var btnLastWeek = 'btn-primary';
	var btnLastMonth = 'btn-primary';

	var btnCustom = 'btn-primary';

	if (props.period === 'today')  btnToday = 'btn-success';
	if (props.period === 'yesterday')  btnYesterday = 'btn-success';
	if (props.period === 'week')  btnWeek = 'btn-success';
	if (props.period === 'month')  btnMonth = 'btn-success';
	if (props.period === 'year')  btnYear = 'btn-success';

	if (props.period === 'lastyear_today')  btnLastYearToday = 'btn-success';
	if (props.period === 'lastyear_yesterday')  btnLastYearYesterday = 'btn-success';
	if (props.period === 'lastyear_week')  btnLastYearWeek = 'btn-success';
	if (props.period === 'lastyear_month')  btnLastYearMonth = 'btn-success';
	if (props.period === 'lastyear_year')  btnLastYearYear = 'btn-success';

	if (props.period === 'lastweek')  btnLastWeek = 'btn-success';
	if (props.period === 'lastmonth')  btnLastMonth = 'btn-success';

	if (props.period === 'custom')  btnCustom = 'btn-success';

	const dateChange = (event) => {
        // debugger;
        let tmpDate = formatDateFromInput(event.target.value);
        if (event.target.id === "date_selected_start"){
            props.setPeriodDateStart(formatDateFromInput(event.target.value));
            // props.setPeriodDateStart(event.target.value);
            return;   
        }        
		props.setPeriodDateEnd(formatDateFromInput(event.target.value));
		// props.setPeriodDateEnd(event.target.value);
	}

	return (
		<ReportTab id='ReportWizard' style={{position:"absolute",top:'0',left:'0',zIndex:"2"}}>
			<ReportTab.Header parentId='ReportWizard' title='Select Period'>
				<BtnExpand parentId='ReportWizard' />
			</ReportTab.Header>
			<ReportTab.AlwaysShow>
				<div className='row flex-nowrap m-0 p-0'>
					<div className='d-grid col m-0 py-0 ps-1 pe-0'><button className={'h-50px btn btn-sm p-0 '+btnYesterday } onClick={() => props.setPeriod('yesterday')}>Yesterday</button></div>
					<div className='d-grid col m-0 py-0 ps-1 pe-0'><button className={'h-50px btn btn-sm p-0 '+btnToday } onClick={() => props.setPeriod('today')}>Today</button></div>
					<div className='d-grid col m-0 py-0 ps-1 pe-0'><button className={'h-50px btn btn-sm p-0 '+btnWeek } onClick={() => props.setPeriod('week')}>This Week</button></div>
					<div className='d-grid col m-0 py-0 ps-1 pe-0'><button className={'h-50px btn btn-sm p-0 '+btnMonth } onClick={() => props.setPeriod('month')}>This Month</button></div>
					<div className='d-grid col m-0 py-0 px-1'><button className={'h-50px btn btn-sm p-0 '+btnYear } onClick={() => props.setPeriod('year')}>This Year</button></div>
				</div>
			</ReportTab.AlwaysShow>
			<ReportTab.Expand parentId='ReportWizard'>				
				<div className='row flex-nowrap my-1 mx-0 p-0'>										
					<div className='d-grid col m-0 py-0 ps-1 pe-0'><button className={'h-50px btn btn-sm p-0 '+btnLastWeek } onClick={() => props.setPeriod('lastweek')}>Last Week</button></div>
					<div className='d-grid col m-0 py-0 ps-1 pe-0'><button className={'h-50px btn btn-sm p-0 '+btnLastMonth } onClick={() => props.setPeriod('lastmonth')}>Last Month</button></div>
                    <div className='d-grid col m-0 py-0 px-1'><button className={'h-50px btn btn-sm p-0 '+btnLastYearYear } onClick={() => props.setPeriod('lastyear_year')}>Last Year</button></div>
					{/* <div className='d-grid col m-0 py-0 px-1'><button className={'h-50px btn btn-sm p-0 '+btnCustom } onClick={() => props.setPeriod('custom')}>Custom</button></div> */}
				</div>
				{/* <div className='row m-0 p-0'>
					<div className='col form-floating mt-2 mb-0 p-1'>
						<input id='date_selected_start' name='date_selected' type='date' value={formatDate(props.periodDateStart)} onChange={dateChange} className='form-control' />
						<label htmlFor='date_selected'>Start Date</label>
					</div>
                    <div className='col form-floating mt-2 mb-0 p-1'>
						<input id='date_selected_end' name='date_selected' type='date' value={formatDate(props.periodDateEnd)} onChange={dateChange} className='form-control' />
						<label htmlFor='date_selected'>End Date</label>
					</div>
				</div> */}
			</ReportTab.Expand>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(ReportWizard);

