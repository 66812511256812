import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { deptName, tlzName, tlzDetail, errorMessage, onlyUnique, rptTotal,dollarUSLocale,formatCurrency } from '../functions';
import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import RptNoData from './views/RptNoData';
import { Collapse } from 'bootstrap';

const mapStateToProps = (state) => {
	return {
		apiServer: state.apiServer,
		agent: state.agent,
		uid: state.uid,
		session: state.session,
		periodType: state.periodType,
		periodDateStart: state.periodDateStart,
		periodDateEnd: state.periodDateEnd,

	}
}

const mapDispatchToProps = { errorMessage }


let didLoad = false;

function RptStoreBalanceSheet(props){
	const [rpt_fin, setRptFin] = useState([]);
	const [rpt_dpt, setRptDpt] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    

	const totalSales = () => {
		if(rpt_dpt.filter(rpt => rpt.F1034 === 3 || rpt.F1034 === 6).length > 0){
            console.log("Total Sales Caclulation**********************************************************");
			return rpt_dpt.filter(rpt => rpt.F1034 === 3 || rpt.F1034 === 6).reduce((a, b) => {                 
                    // console.log(b);
                    // console.log(`***** Current total: ${a} *****`);
                    // console.log(`${b.F238} total: ${b.F65}`);                    
					return a + b.F65
				}, 0);
		}
		return 0;
	};
    
	const totalTaxes = () => {
        let tmpT = rpt_fin.filter(rpt => rpt.F1034 === 22);
        if(rpt_fin.filter(rpt => rpt.F1034 === 22).length > 0){
			return rpt_fin.filter(rpt => rpt.F1034 === 22).reduce((a, b) => { 
					return a + b.F65
				}, 0);
		}
		return 0;
	};

	const totalPlus = () => {
		
        if(rpt_fin.filter(rpt => rpt.F1034 === 39).length > 0){
			return rpt_fin.filter(rpt => rpt.F1034 === 39).reduce((a, b) => { 
					return a + b.F65
				}, 0);
		}
		return 0;
	};

	const totalRevenue = () => {
		if(rpt_fin.filter(rpt => (rpt.F1034 >= 900 && rpt.F1034 <= 999)).length > 0){
			return rpt_fin.filter(rpt => (rpt.F1034 >= 900 && rpt.F1034 <= 999)).reduce((a, b) => {
                                        return a + b.F65
                                }, 0);
		}
		return 0;
	};

	const totalLoans = () => {
		if(rpt_fin.filter(rpt => (rpt.F1034 >= 1000 && rpt.F1034 <= 1099)).length > 0){
			return rpt_fin.filter(rpt => (rpt.F1034 >= 1000 && rpt.F1034 <= 1099)).reduce((a, b) => {
                                        return a + b.F65
                                }, 0);
		}
		return 0;
	};

	const totalPickups = () => {
		if(rpt_fin.filter(rpt => (rpt.F1034 >= 1100 && rpt.F1034 <= 1199)).length > 0){
			return rpt_fin.filter(rpt => (rpt.F1034 >= 1100 && rpt.F1034 <= 1199)).reduce((a, b) => {
                                        return a + b.F65
                                }, 0);
		}
		return 0;
	};

	const totalInDrawer = () => {
		if(rpt_fin.filter(rpt => (rpt.F1034 >= 1300 && rpt.F1034 <= 1399)).length > 0){
			return rpt_fin.filter(rpt => (rpt.F1034 >= 1300 && rpt.F1034 <= 1399)).reduce((a, b) => {
                                        return a + b.F65
                                }, 0);
		}
		return 0;
	};

	const totalShortOver = () => {
		if(rpt_fin.filter(rpt => (rpt.F1034 >= 1400 && rpt.F1034 <= 1499)).length > 0){
			return rpt_fin.filter(rpt => (rpt.F1034 >= 1400 && rpt.F1034 <= 1499)).reduce((a, b) => {
                                        return a + b.F65;
                                }, 0);
		}
		return 0;
	};

	const totalCredits = () => {
        let ts = totalSales();
        let tt = totalTaxes();
        let tp = totalPlus();
        let tso = totalShortOver();
        debugger;
		return totalSales() + totalTaxes() + totalPlus() + totalShortOver() + totalLoans();
	};

	const totalDebits = () => {
		return totalPickups() + totalInDrawer();
	};

    const rpt_dptTotal = (dept) => {
        if(rpt_dpt.filter(rpt => (rpt.F03 === dept)).filter(rpt => (rpt.F1034 === 3 || rpt.F1034 === 6)).length > 0){
            return rpt_dpt.filter(rpt => (rpt.F03 === dept)).filter(rpt => (rpt.F1034 === 3 || rpt.F1034 === 6)).reduce((a, b) => {
                // console.log(`${b.F1039} total is: ${b.F65}`);
                return a + b.F65
            }, 0);
        }
        return 0;
    };
    

	const refresh = () => {
		
        setIsLoading(true);
        didLoad = false;
		let F1031 = props.periodType;
		let F254 = props.periodDateStart;
        
		// logging needed
		fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_dpt&agent='+props.agent+'&F1031='+F1031+'&F254='+F254)
		.then(response => response.json())
		.then(result => {
			// logging needed
			if(result.data){      
                
				setRptDpt(result.data);
                if (didLoad){
                    setIsLoading(false);
                    let collapsible = document.getElementById('collapse_StoreBalanceSheet');
                    if(!collapsible.classList.contains("show")){
                
                        new Collapse(document.getElementById('collapse_StoreBalanceSheet'));
                    }
                    return;   
                }
                didLoad = true;
			}
			else{
				props.errorMessage("Error retrieving rpt_dpt data: "+result.message);
                if (didLoad){
                    setIsLoading(false);
                    return;   
                }
                didLoad = true;
			}
		});

		// logging needed
		fetch(props.apiServer+'?uid='+props.uid+'&session='+props.session+'&action=rpt_fin&agent='+props.agent+'&F1031='+F1031+'&F254='+F254)
		.then(response => response.json())
		.then(result => {
			// logging needed
			if(result.data){
                console.log(result.data);
				setRptFin(result.data); 
                // console.log(result.data[0])               
                if (didLoad){
                    setIsLoading(false);
                    let collapsible = document.getElementById('collapse_StoreBalanceSheet');
                    if(!collapsible.classList.contains("show")){
                
                        new Collapse(document.getElementById('collapse_StoreBalanceSheet'));
                    }
                    return;   
                }
                didLoad = true;
			}
			else{
				props.errorMessage("Error retrieving rpt_fin data: "+result.message);
                if (didLoad){
                    setIsLoading(false);
                    return;   
                }
                didLoad = true;
			}
		});
	}

	
	let departments = rpt_dpt.filter(rpt => rpt.F1034 === 3 || rpt.F1034 === 6)
        .map((record,index,arr) => {
            let tmp = arr;
            return record.F03
        })
        .filter(onlyUnique)
        .map((record, index) => (
		<div key={'dept_'+index} className='row'>
			<div className='col-6 ps-1 py-1'>{deptName(rpt_dpt, record)}</div>
			<div className='col-3 ps-0 py-1'></div>
			<div className='col-3 ps-0 py-1' data-title={`$${formatCurrency(rpt_dptTotal(record))}`}>
                <span className='d-block value-field'>
                    ${formatCurrency(rpt_dptTotal(record))}
                </span>
            </div>
		</div>
	));

	let taxes = rpt_fin.filter(rpt => tlzDetail(props.tlz_tab, rpt.F1034, 'F1179') === 1400 && tlzDetail(props.tlz_tab, rpt.F1034, 'F1253') === 'C').map(record => record.F1034).filter(onlyUnique).map((record, index) => (
		<div key={'taxes_'+index} className='row'>
			<div className='col-md-1 d-none d-md-block m-0 p-1'>{record}</div>
			<div className='col-6 col-md-5 m-0 p-1'>{tlzName(rpt_fin, record)}</div>
			<div className='col-md-2 d-none d-md-block m-0 p-1'>{rptTotal(rpt_fin, record).toFixed(2)}</div>
			<div className='col-3 col-md-2 m-0 p-1'></div>
			<div className='col-3 col-md-2 m-0 p-1'>{rptTotal(rpt_fin, record).toFixed(2)}</div>
		</div>
	));

	let plus = rpt_fin.filter(rpt => tlzDetail(props.tlz_tab, rpt.F1034, 'F1179') === 1500 && tlzDetail(props.tlz_tab, rpt.F1034, 'F1253') === 'C').map(record => record.F1034).filter(onlyUnique).map((record, index) => (
		<div key={'plus_'+index} className='row'>
			<div className='col-md-1 d-none d-md-block m-0 p-1'>{record}</div>
			<div className='col-6 col-md-5 m-0 p-1'>{tlzName(rpt_fin, record)}</div>
			<div className='col-md-2 d-none d-md-block m-0 p-1'>{rptTotal(rpt_fin, record).toFixed(2)}</div>
			<div className='col-3 col-md-2 m-0 p-1'></div>
			<div className='col-3 col-md-2 m-0 p-1'>{rptTotal(rpt_fin, record).toFixed(2)}</div>
		</div>
	));

	
	let revenues = rpt_fin.filter(rpt => (rpt.F1034 >= 900 && rpt.F1034 <= 999)).map(record => record.F1034).filter(onlyUnique).map((record, index) => {
        
        return (
		<div key={'rev_'+index} className='row'>
			<div className='col-md-1 d-none d-md-block m-0 p-1'>{record}</div>
			<div className='col-md-5 d-none d-md-block m-0 p-1'>{tlzName(rpt_fin, record)}</div>
			<div className='col-md-2 d-none d-md-block m-0 p-1'>{formatCurrency(rptTotal(rpt_fin, record))}</div>
			<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
			<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
		</div>
	)});

	
	let loans = rpt_fin.filter(rpt => (rpt.F1034 >= 1000 && rpt.F1034 <= 1099)).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
		<div key={'loan_'+index} className='row'>
            <div className='col-6 ps-1 py-1'>{tlzName(rpt_fin, record)}</div>			
            <div className='col-3 ps-0 py-1'></div>
			<div className='col-3 ps-0 py-1' data-title={`$${formatCurrency(rptTotal(rpt_fin, record).toFixed(2))}`}>
                <span className='d-block value-field'>
                    ${formatCurrency(rptTotal(rpt_fin, record).toFixed(2))}
                </span>
            </div>
		</div>
	));

	
	let pickups = rpt_fin.filter(rpt => (rpt.F1034 >= 1100 && rpt.F1034 <= 1199)).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
		<div key={'pickup_'+index} className='row'>
			<div className='col-md-1 d-none d-md-block m-0 p-1'>{record}</div>
			<div className='col-6 col-md-5 m-0 p-1'>{tlzName(rpt_fin, record)}</div>
			<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
			<div className='col-3 col-md-2 m-0 p-1'>{rptTotal(rpt_fin, record).toFixed(2)}</div>
			<div className='col-3 col-md-2 m-0 p-1'></div>
		</div>
	));

	
	let indrawer = rpt_fin.filter(rpt => (rpt.F1034 >= 1300 && rpt.F1034 <= 1399)).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
		<div key={'drw_'+index} className='row'>			
			<div className='col-6 ps-1 py-1'>{tlzName(rpt_fin, record)}</div>			
			<div className='col-3 ps-0 py-1' data-title={`$${formatCurrency(rptTotal(rpt_fin, record).toFixed(2))}`}>
                <span className='d-block value-field'>
                    ${formatCurrency(rptTotal(rpt_fin, record).toFixed(2))}
                </span>
            </div>
			<div className='col-3 ps-0 py-1'></div>
		</div>
	));

	
	let shortover = rpt_fin.filter(rpt => (rpt.F1034 >= 1400 && rpt.F1034 <= 1499)).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
		<div key={'so_'+index} className='row'>
			<div className='col-md-1 d-none d-md-block m-0 p-1'>{record}</div>
			<div className='col-6 col-md-5 m-0 p-1'>{tlzName(rpt_fin, record)}</div>
			<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
			<div className='col-3 col-md-2 m-0 p-1'></div>
			<div className='col-3 col-md-2 m-0 p-1'>{rptTotal(rpt_fin, record).toFixed(2)}</div>
		</div>
	));

	

	const StoreBalanceSheet = () => {
		if(rpt_dpt.filter(rpt => rpt.F1034 === 3 || rpt.F1034 === 6).length > 0 && rpt_fin.length > 0){
				return (
			<div id='ReportData' className='container'>
				<div className='row'>					
					<div className='col-6 ps-1 py-1'><b>Description</b></div>					
					<div className='col-3 ps-0 py-1'><b>Debit</b></div>
					<div className='col-3 ps-0 py-1'><b>Credit</b></div>
				</div>
				<hr className='m-1 p-0' />
				{departments}
				<div className='row'>
                    <div className='col-6 ps-1 py-1'><b>Total Sales</b></div>                    
                    <div className='col-4 ps-0 py-1'><b>${formatCurrency(totalSales().toFixed(2))}</b></div>
                    <div className='col-2 ps-0 py-1'></div>
				</div>                
				<hr className='m-1 p-0' />	
				{indrawer}
				<div className='row'>                    
                    <div className='col-6 ps-1 py-1'><b>Total Indrawer</b></div>                    
                    <div className='col-4 ps-0 py-1'><b>${formatCurrency(totalInDrawer().toFixed(2))}</b></div>
                    <div className='col-2 ps-0 py-1'></div>
				</div>
				<hr className='m-1 p-0' />
				{shortover}
				<div className='row'>                    
                    <div className='col-6 ps-1 py-1'><b>Total S/O</b></div>                    
                    <div className='col-3 ps-0 py-1'><b>${formatCurrency(totalShortOver().toFixed(2))}</b></div>
                    <div className='col-3 ps-0 py-1'></div>
				</div>
				<hr className='m-1 p-0' />
                {taxes}
				<div className='row'>                    
                    <div className='col-6 ps-1 py-1'><b>Total Taxes</b></div>                    
                    <div className='col-4 ps-0 py-1'><b>${dollarUSLocale.format(totalTaxes().toFixed(2))}</b></div>
                    <div className='col-2 ps-0 py-1'></div>
				</div>
				<hr className='m-1 p-0' />
				{plus}
				<div className='row'>                    
                    <div className='col-6 ps-1 py-1'><b>Total Plus</b></div>                    
                    <div className='col-4 ps-0 py-1'><b>${parseFloat(dollarUSLocale.format(totalPlus())).toFixed(2)}</b></div>
                    <div className='col-2 ps-0 py-1'></div>
				</div>
				<hr className='m-1 p-0' />
				{loans}
				<div className='row'>                    
                    <div className='col-6 ps-1 py-1'><b>Total Loan</b></div>                    
                    <div className='col-3 ps-0 py-1'><b>${formatCurrency(totalLoans().toFixed(2))}</b></div>
                    <div className='col-3 ps-0 py-1'></div>
				</div>
				<hr className='m-1 p-0' />
				{pickups}
				<div className='row'>
                    <div className='col-6 ps-1 py-1'><b>Total Pickups</b></div>                    
                    <div className='col-3 ps-0 py-1'><b>${formatCurrency(totalPickups().toFixed(2))}</b></div>
                    <div className='col-3 ps-0 py-1'></div>
				</div>
				<hr className='m-1 p-0' />
                {revenues}
				<div className='row'>
                    <div className='col-6 ps-1 py-1'><b>Total Revenue</b></div>                    
                    <div className='col-4 ps-0 py-1'><b>${formatCurrency(totalRevenue().toFixed(2))}</b></div>
                    <div className='col-2 ps-0 py-1'></div>
				</div>
                <hr className='m-1 p-0' />
				<div className='row'>					
					<div className='col-6 ps-1 py-1'><b>Total Debit/Credit</b></div>
					<div className='col-3 ps-0 py-1' data-title={`$${formatCurrency(totalDebits().toFixed(2))}`}>
                        <span className='d-block value-field'><b>${formatCurrency(totalDebits().toFixed(2))}</b></span>
                    </div>
					<div className='col-3 ps-0 py-1' data-title={`$${formatCurrency(totalCredits().toFixed(2))}`}>
                        <span className='d-block value-field'><b>${formatCurrency(totalCredits().toFixed(2))}</b></span>
                    </div>
				</div>
			</div>
			);
		}
		else{
			return(
				<RptNoData />
			);
		}
	}

	return (
		<ReportTab id='StoreBalanceSheet'>
			<ReportTab.Header parentId='StoreBalanceSheet' title='Store Balance Sheet'>
				<BtnRefresh parentId='StoreBalanceSheet' loading={isLoading} action={refresh}/>
				<BtnExpand parentId='StoreBalanceSheet' action={refresh} />
			</ReportTab.Header>
			<ReportTab.Expand parentId='StoreBalanceSheet'>
				{StoreBalanceSheet()} 
			</ReportTab.Expand>
		</ReportTab>
	)
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptStoreBalanceSheet);

