import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import RptMultiTotal from './views/RptMultiTotal';
import { Collapse } from 'bootstrap';

import { errorMessage, cmp, onlyUnique, clkName, tlzName, rptTotalQty, rptTotal } from '../functions';

const mapStateToProps = (state) => {
	return {
		apiServer: state.apiServer,
		agent: state.agent,
		uid: state.uid,
		session: state.session,
		periodType: state.periodType,
		periodDateStart: state.periodDateStart,
		periodDateEnd: state.periodDateEnd
	}
}

const mapDispatchToProps = { errorMessage }

function RptFunctionFraudWatch(props){
	const [rpt_clk, setRptClk] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

	const refresh = () => {
		setIsLoading(true);

		var F1031 = props.periodType;
		var F254 = props.periodDateStart;

		// logging needed

		fetch(props.apiServer+'?uid='+props.uid+'&session='+props.session+'&action=rpt_clk_'+F1031+'&agent='+props.agent+'&F254='+F254)
		.then(response => response.json())
		.then(result => {
			// logging needed
			if(result.data){
				setRptClk(result.data);
                setIsLoading(false);
                let collapsible = document.getElementById('collapse_StoreFraudWatchByFunction');
                if(!collapsible.classList.contains("show")){
                    new Collapse(document.getElementById('collapse_StoreFraudWatchByFunction'));
                }
			}
			else{
				props.errorMessage("Error retrieving rpt_clk data: "+result.message);
                setIsLoading(false);
			}
		});
	};

	let fraudTotalizers = [
		111,
		3002,
		3003,
		3004,
		3005,
		3011,
		3101,
		3112,
		3305,
		4111
	];

	let filteredData = rpt_clk.filter((record) => fraudTotalizers.includes(record.F1034) === true);
    
    let data = [];
    fraudTotalizers.forEach(totalizer => {
        let res = filteredData.filter(data => data.F1034 === totalizer).sort(function (a,b) {
            if (a.F1034 == 3101){
                return cmp(b.F64,a.F64);    
            }
            return cmp(b.F65,a.F65);
        })
        if (res.length > 0){
            let tmpObj = {
                description: `${res[0].F1039}`,
                // description: `${res[0].F1039}`,
                data: res.map(data => {
                    return ({
                        code: totalizer,
                        description: `${data.F1127}`,
                        qty: data.F64,
                        dollars: data.F65
                    })
                })
            }
            data.push(tmpObj);
        }
    })
    

	return (
		<ReportTab id='StoreFraudWatchByFunction'>
			<ReportTab.Header parentId='StoreFraudWatchByFunction' title='Fraud Watch by Function'>
				<BtnRefresh parentId='StoreFraudWatchByFunction' loading={isLoading} action={refresh} />
				<BtnExpand parentId='StoreFraudWatchByFunction' action={refresh} />
			</ReportTab.Header>
			<ReportTab.Expand parentId='StoreFraudWatchByFunction'>
				<RptMultiTotal data={data} />
			</ReportTab.Expand>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptFunctionFraudWatch);
