import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import RptSummaryInformation from './views/RptSummaryInformation';
import RptSafeInformation from './views/RptSafeInformation';
import RptDrawerInformation from './views/RptDrawerInformation';
import RptNoData from './views/RptNoData';
import { Collapse } from 'bootstrap';

import { errorMessage } from '../functions';

const mapStateToProps = (state) => {
	return {
		apiServer: state.apiServer,
		agent: state.agent,
		uid: state.uid,
		session: state.session,
		periodType: state.periodType,
		periodDateStart: state.periodDateStart,
		periodDateEnd: state.periodDateEnd
	}
}

const mapDispatchToProps = { errorMessage }

function RptStoreTotalSummary(props){
	const [rpt_fin, setRptFin] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

	const refresh = () => {
		
        setIsLoading(true);
        
		var F1031 = props.periodType;
		var F254 = props.periodDateStart;

		// logging needed
        
		fetch(props.apiServer+'?uid='+props.uid+'&session='+props.session+'&action=rpt_fin&agent='+props.agent+'&F1031='+F1031+'&F254='+F254)
		.then(response => response.json())
		.then(result => {
			// logging needed
			if(result.data){
                
				setRptFin(result.data);
                let collapsible = document.getElementById('collapse_TotalStoreSummary');
                if(!collapsible.classList.contains("show")){
			
                    new Collapse(document.getElementById('collapse_TotalStoreSummary'));
                }
                setIsLoading(false);
			}
			else{
				props.errorMessage("Error retrieving rpt_fin data: "+result.message);
                setIsLoading(false);
			}
            
		});
	}

	const TotalStoreSummary = () => {
		if(rpt_fin.length > 0){
			return (
				<div id='ReportData' className='container'>
					<RptSummaryInformation data={rpt_fin} />
					<RptSafeInformation data={rpt_fin} />
					<RptDrawerInformation data={rpt_fin} />
				</div>
			);
		}
		else{
			return (
				<RptNoData />
			);
		}
	}

	return (
		<ReportTab id='TotalStoreSummary'>
			<ReportTab.Header parentId='TotalStoreSummary' title='Total Store Summary'>
				<BtnRefresh parentId='TotalStoreSummary' loading={isLoading} action={refresh} />
				<BtnExpand parentId='TotalStoreSummary' action={refresh} />
			</ReportTab.Header>
			<ReportTab.Expand parentId='TotalStoreSummary'>
				{TotalStoreSummary()} 
			</ReportTab.Expand>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptStoreTotalSummary);

