import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import RptMultiTotal from './views/RptMultiTotal';
import { Collapse } from 'bootstrap';

import { errorMessage, onlyUnique, cmp, tlzName, sdpName, rptTotalQty, rptTotal } from '../functions';

const mapStateToProps = (state) => {
        return {
		apiServer: state.apiServer,
		agent: state.agent,
		uid: state.uid,
		session: state.session,
        periodType: state.periodType,
        periodDateStart: state.periodDateStart,
        periodDateEnd: state.periodDateEnd
    }
}

const mapDispatchToProps = { errorMessage }

function RptSubDeptMultiTotal(props){
	const [rpt_sub, setRptSub] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    

	const refresh = () => {
		setIsLoading(true);

		var F1031 = props.periodType;
		var F254 = props.periodDateStart;

		// logging needed
		fetch(props.apiServer+'?uid='+props.uid+'&session='+props.session+'&action=rpt_sub&agent='+props.agent+'&F1031='+F1031+'&F254='+F254)
		.then(response => response.json())
		.then(result => {
			// logging needed
			if(result.data){
				setRptSub(result.data);
                let collapsible = document.getElementById('collapse_SubDeptMultiTotals');
                if(!collapsible.classList.contains("show")){
                    new Collapse(document.getElementById('collapse_SubDeptMultiTotals'));
                }
                setIsLoading(false);
			}
			else{
				props.errorMessage("Error retrieving rpt_sub data: "+result.message);
                setIsLoading(false);
			}
		});
	};

        let rptData = rpt_sub.map((record) => record.F04).filter(onlyUnique).sort().map((sdpNo) => {
		return ({
			description: sdpNo + ' ' + sdpName(rpt_sub, sdpNo),
			data: rpt_sub.filter((data) => data.F04 === sdpNo).sort(function (a, b) {
				return cmp(a.F1179, b.F1179) || cmp(a.F1034,b.F1034);
			}).map((record) => record.F1034).filter(onlyUnique).map((totalizer) => {
				return ({
					code: totalizer,
					description: tlzName(rpt_sub, totalizer),
					qty: rptTotalQty(rpt_sub.filter((rpt) => rpt.F04 === sdpNo), totalizer),
					dollars: rptTotal(rpt_sub.filter((rpt) => rpt.F04 === sdpNo), totalizer)
				})
			})
		});
	});

	return (
		<ReportTab id='SubDeptMultiTotals'>
			<ReportTab.Header title='Sub-dept. Multi Totals'>
				<BtnRefresh parentId='SubDeptMultiTotals' loading={isLoading} action={refresh} />
				<BtnExpand parentId='SubDeptMultiTotals' action={refresh} />
			</ReportTab.Header>
			<ReportTab.Expand parentId='SubDeptMultiTotals'>
				<RptMultiTotal data={rptData} />
			</ReportTab.Expand>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptSubDeptMultiTotal);

