import React from 'react';

function RptNoData(props){
	return (
		<div id='NoDataFound' className='d-flex justify-content-center'>
                       	<b>No data found</b>
		</div>
	);
}

export default RptNoData;
