import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import Rpt2SingleTotal from './views/Rpt2SingleTotal';
import { Collapse } from 'bootstrap';

import { errorMessage, onlyUnique, clkName, rptTotal } from '../functions';

const mapStateToProps = (state) => {
        return {
		apiServer: state.apiServer,
		agent: state.agent,
		uid: state.uid,
		session: state.session,
        periodType: state.periodType,
        periodDateStart: state.periodDateStart,
        periodDateEnd: state.periodDateEnd
    }
}

const mapDispatchToProps = { errorMessage }

function RptCashierSingleTotal(props) {
	const [rpt_clk, setRptClk] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    
    const refresh = () => {
        setIsLoading(true);    
        
        var F1031 = props.periodType;
        var F254 = props.periodDateStart;

        // logging needed
        fetch(props.apiServer+'?uid='+props.uid+'&session='+props.session+'&action=rpt_clk_'+F1031+'&agent='+props.agent+'&F254='+F254)
		.then(response => response.json())
		.then(result => {
            // logging needed
			if(result.data){                           
                setRptClk(result.data);
                setIsLoading(false);
                let collapsible = document.getElementById('collapse_CashierSingleTotal');
                if(!collapsible.classList.contains("show")){
                    new Collapse(document.getElementById('collapse_CashierSingleTotal'));
                }
			}
			else{
				props.errorMessage("Error retrieving rpt_clk data: rpt_clk_" + F1031 + " " + result.message);
                setIsLoading(false);
			}
        });
    }

    let rptData = rpt_clk.map((record) => record.F1126).filter(onlyUnique).sort().map((cashier) => {
        return ({
                code: cashier,
                description: clkName(rpt_clk, cashier),
                total1: rptTotal(rpt_clk.filter((rpt) => rpt.F1126 === cashier), 1301),
                total2: rptTotal(rpt_clk.filter((rpt) => rpt.F1126 === cashier), 3)
        });
    });
	let total1 = rptTotal(rpt_clk, 1301);
    let total2 = rptTotal(rpt_clk, 3);

	return (
		<ReportTab id='CashierSingleTotal'>
			<ReportTab.Header parentId='CashierSingleTotal' title='Cashier Single Total'>
				<BtnRefresh parentId='CashierSingleTotal' loading={isLoading} action={refresh} />
                <BtnExpand parentId='CashierSingleTotal' action={refresh} />
			</ReportTab.Header>
			<ReportTab.Expand parentId='CashierSingleTotal'>
				<Rpt2SingleTotal title='Cashier Single Total' label1='In Drawer' label2='Net Sales' description='Cashier' data={rptData} total1={total1} total2={total2} />
			</ReportTab.Expand>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptCashierSingleTotal);

