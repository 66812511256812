import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
        return {
			apiServer: state.apiServer,
			agent: state.agent,
			uid: state.uid,
			session: state.session,
			periodType: state.periodType,
			periodDate: state.periodDate
        }
}

const mapDispatchToProps = { }

function StoreInformation(props){
	const [store, setStore] = useState('');
	const [location, setLocation] = useState('');

	useEffect(() => {
		// logging needed
		fetch(props.apiServer+'?uid='+props.uid+'&session='+props.session+'&action=get_agent&agent='+props.agent)
		.then(response => response.json())
		.then(result => {
			// logging needed
			if(result.data){
				setStore(result.data.store);
				setLocation(result.data.location);
			}
			else{
				props.errorMessage("Error retrieving get_agent data: "+result.message);
			}
		});
	}, [props, props.apiServer, props.uid, props.session, props.agent]);

	return (
		<div className='d-flex flex-row theme-secondary'>
			<div className='d-flex mx-3 my-1'>Store: {store}</div>
			<div className='d-flex mx-3 my-1'>Location: {location}</div>
		</div>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(StoreInformation);
