import React from 'react';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import RptNoData from './RptNoData';
import { formatCurrency } from '../../functions';



function RptMultiTotal(props){
    // debugger;
    if(!props.data){
        return (
            <RptNoData />
        );
    }

	if(props.data.length > 0){
		

		const multitotal = () => {
			return props.data.map((record,index) => {
				return (
                    <div key={'mt_'+index}>
                        <div className='row'>
                                <div className='col-12'><b>{record.description}</b></div>
                        </div>
                        {multitotal_details(record.data)}
                        {record.total ? <div className='row'>
                                <div className='col-6' />
                                <div className='col-6 text-end'><b>Total: {formatCurrency(record.total.toFixed(2))}</b></div>
                            </div> : null }
                    </div>
                );
			});
		}

		const multitotal_details = (data) => {
			
			return data.map((record, index) => (
                <div key={'mt_details_'+index} className='row'>
                    <div className='col-6'>
                        <div className='ms-2' data-ltitle={record.description}>
                            <span className='d-block value-field'>
                                {record.description}
                            </span>
                        </div>
                    </div>
                    <div className='col-3 text-center'>{record.qty.toFixed(0) !== 0 ? record.qty.toFixed(0) : null}</div>
                    <div className='col-3 text-left' data-rtitle={`$${formatCurrency(record.dollars.toFixed(2))}`}>
                        <span className='d-block value-field'>
                            {record.dollars.toFixed(2) !== 0.00 ? `$${formatCurrency(record.dollars.toFixed(2))}` : "$0.00"}
                        </span>
                    </div>
				</div>
			));
		}

    		return (
			<div id='ReportData' className='container'>
				<div className='row'>
					<div className='col-6'><div className='ms-1'><b>Description</b></div></div>
					<div className='col-3 text-center'><b>Qty</b></div>
					<div className='col-3 text-left'><b>Amt</b></div>
				</div>
				<hr className='m-1 p-0' />
				{multitotal()}
			</div>
		);
	}
	else{
		return (
			<RptNoData />
		);
	}
}

export default RptMultiTotal;
