import React from 'react';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function RptSingleTotalXLS (props) {
    return (
        <ExcelFile filename={props.filename} element={<button className='btn btn-secondary p-1'><i className="icon bi bi-filetype-xlsx fa-2xl"></i></button>}>
            <ExcelSheet data={props.data} name="ReportData">
                <ExcelColumn label="Code" value="code"/>
                <ExcelColumn label="Description" value="description"/>
                <ExcelColumn label="Qty" value="qty"/>
                <ExcelColumn label="Amnt" value="dollars"/>
            </ExcelSheet>
        </ExcelFile>
    );
}

export default RptSingleTotalXLS;