import React from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ErrorBoundry from './ErrorBoundry';
import ReportWizard from './ReportWizard';
import ReportTab from './ReportTab';
import BtnExpand from './buttons/BtnExpand';
import StoreList from './StoreList';
import RptQuickView from './reports/RptQuickView';
// STORE REPORTS
import RptStoreTotalSummary from './reports/RptStoreTotalSummary';
import RptStoreBalanceSheet from './reports/RptStoreBalanceSheet';
import RptStoreMultiTotal from './reports/RptStoreMultiTotal';
// DEPARTMENT REPORTS
import RptDepartmentSingleTotal from './reports/RptDepartmentSingleTotal';
import RptDepartmentMultiTotal from './reports/RptDepartmentMultiTotal';
// SUB-DEPARTMENT REPORTS
import RptSubDeptSingleTotal from './reports/RptSubDeptSingleTotal';
import RptSubDeptMultiTotal from './reports/RptSubDeptMultiTotal';
import RptSubDeptByDept from './reports/RptSubDeptByDept';
// CASHIER REPORTS
import RptCashierSingleTotal from './reports/RptCashierSingleTotal';
import RptCashierMultiTotal from './reports/RptCashierMultiTotal';
import RptCashierShortOver from './reports/RptCashierShortOver';
import RptCashierFraudWatch from './reports/RptCashierFraudWatch';
// OTHER REPORTS
import RptOthersCategorySingleTotal from './reports/RptOthersCategorySingleTotal';
import RptOthersCategoryMultiTotal from './reports/RptOthersCategoryMultiTotal';
import RptOthersFamilyCodeSingleTotal from './reports/RptOthersFamilyCodeSingleTotal';
import RptOthersFamilyCodeMultiTotal from './reports/RptOthersFamilyCodeMultiTotal';
import RptOthersReportCodeSingleTotal from './reports/RptOthersReportCodeSingleTotal';
import RptOthersReportCodeMultiTotal from './reports/RptOthersReportCodeMultiTotal';

import { errorMessage } from './functions';
import RptHourlyMultiTotal from './reports/RptHourlyMultiTotal';
import RptHourlySingleTotal from './reports/RptHourlySingleTotal';
import RptFunctionFraudWatch from './reports/RptFunctionFraudWatch';

const mapStateToProps = (state) => {
        return {
			version: state.version,
			apiServer: state.apiServer,
			uid: state.uid,
			session: state.session,
			agent: state.agent
        }
}

const mapDispatchToProps = { errorMessage }


function ReportList(props){
	if(!props.agent){
		return (<StoreList />);
	}

	return (
		<div className='m-0' style={{zIndex:"1"}}>
			<RptQuickView />

			<div style={{height:'30px'}}></div>

			<ReportWizard style={{position:"fixed",top:"0"}} />

			<ReportTab id='Store'>
				<ReportTab.Header parentId='Store' title='Store'>
					<BtnExpand parentId='Store' />
				</ReportTab.Header>
				<ReportTab.Expand parentId='Store'>
					<ErrorBoundry><RptStoreTotalSummary /></ErrorBoundry>
					<ErrorBoundry><RptStoreBalanceSheet /></ErrorBoundry>
					<ErrorBoundry><RptStoreMultiTotal /></ErrorBoundry>
                    <ErrorBoundry><RptHourlySingleTotal /></ErrorBoundry>
					<div className='p-3'></div>					
				</ReportTab.Expand>
			</ReportTab>

			<ReportTab id='Departments'>
				<ReportTab.Header parentId='Departments' title='Departments'>
					<BtnExpand parentId='Departments' />
				</ReportTab.Header>
				<ReportTab.Expand parentId='Departments'>
					<ErrorBoundry><RptDepartmentSingleTotal /></ErrorBoundry>
					<ErrorBoundry><RptDepartmentMultiTotal /></ErrorBoundry>
					<div className='p-3'></div>
				</ReportTab.Expand>
			</ReportTab>

			<ReportTab id='SubDepartments'>
				<ReportTab.Header parentId='SubDepartments' title='Sub-departments'>
					<BtnExpand parentId='SubDepartments' />
				</ReportTab.Header>
				<ReportTab.Expand parentId='SubDepartments'>
					<ErrorBoundry><RptSubDeptSingleTotal /></ErrorBoundry>
					<ErrorBoundry><RptSubDeptMultiTotal /></ErrorBoundry>
					<ErrorBoundry><RptSubDeptByDept /></ErrorBoundry>
					<div className='p-3'></div>					
				</ReportTab.Expand>
			</ReportTab>

			<ReportTab id='Cashiers'>
				<ReportTab.Header parentId='Cashiers' title='Cashiers'>
					<BtnExpand parentId='Cashiers' />
				</ReportTab.Header>
				<ReportTab.Expand parentId='Cashiers'>
					<ErrorBoundry><RptCashierSingleTotal /></ErrorBoundry>
					<ErrorBoundry><RptCashierMultiTotal /></ErrorBoundry>
					<ErrorBoundry><RptCashierShortOver /></ErrorBoundry>
					<ErrorBoundry><RptCashierFraudWatch /></ErrorBoundry>
                    <ErrorBoundry><RptFunctionFraudWatch /></ErrorBoundry>
					<div className='p-3'></div>
				</ReportTab.Expand>
			</ReportTab>

			<ReportTab id='Others'>
				<ReportTab.Header parentId='Others' title='Others'>
					<BtnExpand parentId='Others' />
				</ReportTab.Header>
				<ReportTab.Expand parentId='Others'>
					<ErrorBoundry><RptOthersCategorySingleTotal /></ErrorBoundry>
					<ErrorBoundry><RptOthersCategoryMultiTotal /></ErrorBoundry>
					<ErrorBoundry><RptOthersFamilyCodeSingleTotal /></ErrorBoundry>
					<ErrorBoundry><RptOthersFamilyCodeMultiTotal /></ErrorBoundry>
					<ErrorBoundry><RptOthersReportCodeSingleTotal /></ErrorBoundry>
					<ErrorBoundry><RptOthersReportCodeMultiTotal /></ErrorBoundry>
					<div className='p-3'></div>
				</ReportTab.Expand>
			</ReportTab>

            {/* <ReportTab id='Customers'>
				<ReportTab.Header parentId='Customers' title='Customers'>
					<BtnExpand parentId='Customers' />
				</ReportTab.Header>
				<ReportTab.Expand parentId='Customers'>
					
					<div className='p-3'></div>
				</ReportTab.Expand>
			</ReportTab> */}
		</div>

	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(ReportList);
