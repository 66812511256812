import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import RptSingleTotal from './views/RptSingleTotal';
import { Collapse } from 'bootstrap';

import { errorMessage, onlyUnique, deptName, rptTotal, rptTotalQty } from '../functions';

const mapStateToProps = (state) => {
    return {
		apiServer: state.apiServer,
		agent: state.agent,
		uid: state.uid,
		session: state.session,
        periodType: state.periodType,
        periodDateStart: state.periodDateStart,
        periodDateEnd: state.periodDateEnd
    }
}

const mapDispatchToProps = { errorMessage }

function RptSubDeptSingleTotal(props) {
	const [rpt_dpt, setRptDpt] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const refresh = () => {
        setIsLoading(true);
        // debugger;
        var F1031 = props.periodType;
        var F254 = props.periodDateStart;

        // logging needed

        fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_dpt&agent='+props.agent+'&F1031='+F1031+'&F254='+F254+'&F1034=3')
        .then(response => response.json())
        .then(result => {
            // logging needed
            if(result.data){                
                setRptDpt(result.data);
                setIsLoading(false);
                let collapsible = document.getElementById('collapse_DepartmentSingleTotal');
                if(!collapsible.classList.contains("show")){            
                    new Collapse(document.getElementById('collapse_DepartmentSingleTotal'));
                }
            }
            else{
                props.errorMessage("Error retrieving rpt_dpt data: "+result.message);
                setIsLoading(false);
            }
        });
    }

	let rptData = rpt_dpt.map((record) => record.F03).filter(onlyUnique).sort().map((deptNo) => {
		return ({
			code: deptNo,
			description: deptName(rpt_dpt, deptNo),
			qty: rptTotalQty(rpt_dpt.filter((rpt) => rpt.F03 === deptNo), 3),
			dollars: rptTotal(rpt_dpt.filter((rpt) => rpt.F03 === deptNo), 3)
		});
	});

	return (
		<ReportTab id='DepartmentSingleTotal'>
			<ReportTab.Header parentId='DepartmentSingleTotal' title='Department Single Total'>
				<BtnRefresh parentId='DepartmentSingleTotal' loading={isLoading} action={refresh} />
				<BtnExpand parentId='DepartmentSingleTotal' action={refresh} />
			</ReportTab.Header>
			<ReportTab.Expand parentId='DepartmentSingleTotal'>
				<RptSingleTotal title='Department Net Sales' description='Department' data={rptData} />
			</ReportTab.Expand>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptSubDeptSingleTotal);

