import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import RptSingleTotal from './views/RptSingleTotal';
import { Collapse } from 'bootstrap';

import { errorMessage, famName } from '../functions';

const mapStateToProps = (state) => {
        return {
		apiServer: state.apiServer,
		agent: state.agent,
		uid: state.uid,
		session: state.session,
        periodType: state.periodType,
        periodDateStart: state.periodDateStart,
        periodDateEnd: state.periodDateEnd
    }
}

const mapDispatchToProps = { errorMessage }

function RptOthersFamilyCodeSingleTotal(props){
	const [rpt_fam, setRptFam] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    const refresh = () => {
        setIsLoading(true);    

        var F1031 = props.periodType;
        var F254 = props.periodDateStart;

        // logging needed
        fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_fam&agent='+props.agent+'&F1031='+F1031+'&F254='+F254+'&F1034=3')
		.then(response => response.json())
		.then(result => {
                  // logging needed
			if(result.data){                
                setRptFam(result.data);
                setIsLoading(false);
                let collapsible = document.getElementById('collapse_FamilyCodeSingleTotal');
                if(!collapsible.classList.contains("show")){
                    new Collapse(document.getElementById('collapse_FamilyCodeSingleTotal'));
                }
			}
			else{
				props.errorMessage("Error retrieving rpt_fam message: "+result.message);
                setIsLoading(false);
			}
        });
    }

    let rptData = rpt_fam.map((record,index) => {
        return {
            code: record.F16,
            description: famName(rpt_fam, record.F16),
            qty: record.F64,
            dollars: record.F65
        };
    });

	return (
		<ReportTab id='FamilyCodeSingleTotal'>
            <ReportTab.Header parentId='FamilyCodeSingleTotal' title='Family Code Single Total'>
				<BtnRefresh parentId='FamilyCodeSingleTotal' loading={isLoading} action={refresh} />
				<BtnExpand parentId='FamilyCodeSingleTotal' action={refresh} />
			</ReportTab.Header>
			<ReportTab.Expand parentId='FamilyCodeSingleTotal'>
				<RptSingleTotal title='Family Code Net Sales' description='Family Code' data={rptData} />
			</ReportTab.Expand>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptOthersFamilyCodeSingleTotal);

