import { tlzName, onlyUnique, rptTotal, rptTotalTlzRange,formatCurrency } from '../../functions';

function RptDrawerInformation(props) {
	const totalPickups = () => {
		return rptTotalTlzRange(props.data,1100,1199);
	};

	const totalLoans = () => {
		return rptTotalTlzRange(props.data,1000,1099);
	};

	const totalDrwStart = () => {
		return rptTotalTlzRange(props.data,900,999);
	};

	const totalRevenue = () => {
		return rptTotalTlzRange(props.data,900,999);
	};

	const totalExpected = () => {
		return rptTotalTlzRange(props.data,1200,1299);
	};

	const totalDrwShortOver = () => {
		return rptTotalTlzRange(props.data,1400,1499);
	};

	const totalDrwEnding = () => {
		return rptTotalTlzRange(props.data,1300,1399);
	};

	
	let pickups = props.data.filter(rpt => rpt.F1034 >= 1100 && rpt.F1034 < 1199).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
        <div key={'pickups_'+index} className='row'>
            <div className='col-8'>{tlzName(props.data, record-1000)}</div>
            <div className='col-4'>{rptTotal(props.data.filter(rpt => rpt.F1034 >= 1100 && rpt.F1034 < 1199), record).toFixed(2)}</div>
        </div>
	));

	
	let loans = props.data.filter(rpt => rpt.F1034 >= 1000 && rpt.F1034 < 1099).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
        <div key={'loans_'+index} className='row'>
            <div className='col-8'>{tlzName(props.data, record-900)}</div>
            <div className='col-4'>{rptTotal(props.data.filter(rpt => rpt.F1034 >= 1000 && rpt.F1034 < 1099), record).toFixed(2)}</div>
        </div>
	));

	
	let drw_start = props.data.filter(rpt => rpt.F1034 >= 2000 && rpt.F1034 < 2099).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
        <div key={'drw_start_'+index} className='row'>
            <div className='col-8'>{tlzName(props.data, record-1900)}</div>
            <div className='col-4'>{rptTotal(props.data.filter(rpt => rpt.F1034 >= 2000 && rpt.F1034 < 2099), record).toFixed(2)}</div>
        </div>
	));

	
	let revenue = props.data.filter(rpt => rpt.F1034 >= 900 && rpt.F1034 < 999).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
        <div key={'revenue_'+index} className='row'>
            <div className='col-8' data-ltitle={tlzName(props.data, record-800)}>
                <span className='d-block value-field'>
                    {tlzName(props.data, record-800)}
                </span>
            </div>
            <div className='col-4' data-title={`$${rptTotal(props.data.filter(rpt => rpt.F1034 >= 900 && rpt.F1034 < 999), record).toFixed(2)}`}>
                <span className='d-block value-field'>
                    {`$${formatCurrency(rptTotal(props.data.filter(rpt => rpt.F1034 >= 900 && rpt.F1034 < 999), record).toFixed(2))}`}
                </span>
            </div>
        </div>
	));

	
	let expected = props.data.filter(rpt => rpt.F1034 >= 1200 && rpt.F1034 < 1299).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
        <div key={'expected_'+index} className='row'>
            <div className='col-8' data-ltitle={tlzName(props.data, record-1100)}>
                <span className='d-block value-field'>
                    {tlzName(props.data, record-1100)}
                </span>
            </div>
            <div className='col-4' data-title={`$${formatCurrency(rptTotal(props.data.filter(rpt => rpt.F1034 >= 1200 && rpt.F1034 < 1299), record).toFixed(2))}`}>
                <span className='d-block value-field'>
                    {`$${formatCurrency(rptTotal(props.data.filter(rpt => rpt.F1034 >= 1200 && rpt.F1034 < 1299), record).toFixed(2))}`}
                </span>
            </div>
        </div>
	));


	
	let drw_shortover = props.data.filter(rpt => rpt.F1034 >= 1400 && rpt.F1034 < 1499).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
        <div key={'drw_shortover_'+index} className='row'>
            <div className='col-8' data-ltitle={tlzName(props.data, record-1300)}>
                <span className='d-block value-field'>
                    {tlzName(props.data, record-1300)}
                </span>
            </div>
            <div className='col-4' data-title={`$${formatCurrency(rptTotal(props.data.filter(rpt => rpt.F1034 >= 1400 && rpt.F1034 < 1499), record).toFixed(2))}`}>
                <span>
                    {`$${formatCurrency(rptTotal(props.data.filter(rpt => rpt.F1034 >= 1400 && rpt.F1034 < 1499), record).toFixed(2))}`}
                </span>
            </div>
        </div>
	));

	
	let drw_ending = props.data.filter(rpt => rpt.F1034 >= 1300 && rpt.F1034 < 1399).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
        <div key={'drw_ending_'+index} className='row'>
            <div className='col-8' data-ltitle={tlzName(props.data, record-1200)}>
                <span className='d-block value-field'>
                    {tlzName(props.data, record-1200)}
                </span>
            </div>
            <div className='col-4' data-rtitle={`$${formatCurrency(rptTotal(props.data.filter(rpt => rpt.F1034 >= 1300 && rpt.F1034 < 1399), record).toFixed(2))}`}>
                <span className='d-block value-field'>
                    {`$${formatCurrency(rptTotal(props.data.filter(rpt => rpt.F1034 >= 1300 && rpt.F1034 < 1399), record).toFixed(2))}`}
                </span>
            </div>
        </div>
	));

    return (
        <>
                <div className='mt-2 row bg-secondary text-white'>
                    <div className='col-12'><b>Drawer Information</b></div>
                </div>
                <div className='row'>
                    <div className='col-8'><b>Drawer Start</b></div>
                    <div className='col-4'></div>
                </div>
                {drw_start}
                <div className='row'>
                    <div className='col-4'></div>
                    <div className='col-4 text-right pr-0'><b>Total</b></div>
                    <div className='col-4' data-title={`$${formatCurrency(totalDrwStart().toFixed(2))}`}>
                        <span className='d-block value-field'>
                            <b>${formatCurrency(totalDrwStart().toFixed(2))}</b>
                        </span>
                    </div>
                </div>



                <hr className='m-1 p-0' />
                <div className='row'>
                    <div className='col-8'><b>Revenue</b></div>
                    <div className='col-4'></div>
                </div>
                {revenue}
                <div className='row'>
                    <div className='col-4'></div>
                    <div className='col-4 text-right pr-0'><b>Total</b></div>
                    <div className='col-4' data-title={`$${formatCurrency(totalRevenue().toFixed(2))}`}>
                        <span className='d-block value-field'>
                            <b>${formatCurrency(totalRevenue().toFixed(2))}</b>
                        </span>
                    </div>
                </div>



                <hr className='m-1 p-0' />
                <div className='row'>
                    <div className='col-8'><b>Loan</b></div>
                    <div className='col-4'>&nbsp;</div>
                </div>
                {loans}
                <div className='row'>
                    <div className='col-4'>&nbsp;</div>
                    <div className='col-4 text-right pr-0'><b>Total</b></div>
                    <div className='col-4' data-title={`$${formatCurrency(totalLoans().toFixed(2))}`}>
                        <span className='d-block value-field'>
                            <b>${formatCurrency(totalLoans().toFixed(2))}</b>
                        </span>
                    </div>
                </div>



                <hr className='m-1 p-0' />
                <div className='row'>
                    <div className='col-8'><b>Pickup</b></div>
                    <div className='col-4'>&nbsp;</div>
                </div>
                {pickups}
                <div className='row'>
                    <div className='col-4'>&nbsp;</div>
                    <div className='col-4 text-right pr-0'><b>Total</b></div>
                    <div className='col-4' data-title={`$${formatCurrency(totalPickups().toFixed(2))}`}>
                        <span className='d-block value-field'>
                            <b>${formatCurrency(totalPickups().toFixed(2))}</b>
                        </span>
                    </div>                    
                </div>



                <hr className='m-1 p-0' />
                <div className='row'>
                    <div className='col-8'><b>Expected</b></div>
                    <div className='col-4'>&nbsp;</div>
                </div>
                {expected}
                <div className='row'>
                    <div className='col-4'>&nbsp;</div>
                    <div className='col-4 text-right pr-0'><b>Total</b></div>
                    <div className='col-4' data-title={`$${formatCurrency(totalExpected().toFixed(2))}`}>
                        <span className='d-block value-field'>
                            <b>${formatCurrency(totalExpected().toFixed(2))}</b>
                        </span>
                    </div>                       
                </div>



                <hr className='m-1 p-0' />
                <div className='row'>
                    <div className='col-8'><b>Short / Over</b></div>
                    <div className='col-4'>&nbsp;</div>
                </div>
                {drw_shortover}
                <div className='row'>
                    <div className='col-4'>&nbsp;</div>
                    <div className='col-4 text-right pr-0'><b>Total</b></div>
                    <div className='col-4' data-title={`$${formatCurrency(totalDrwShortOver().toFixed(2))}`}>
                        <span className='d-block value-field'>
                            <b>${formatCurrency(totalDrwShortOver().toFixed(2))}</b>
                        </span>
                    </div>                    
                </div>



                <hr className='m-1 p-0' />
                <div className='row'>
                    <div className='col-8'><b>Drw. Ending</b></div>
                    <div className='col-4'>&nbsp;</div>
                </div>
                {drw_ending}
                <div className='row'>
                    <div className='col-4'>&nbsp;</div>
                    <div className='col-4 text-right pr-0'><b>Total</b></div>
                    <div className='col-4' data-title={`$${formatCurrency(totalDrwEnding().toFixed(2))}`}>
                        <span className='d-block value-field'>
                            <b>${formatCurrency(totalDrwEnding().toFixed(2))}</b>
                        </span>
                    </div>
                </div>
        </>
    );
}

export default RptDrawerInformation;