import { tlzName, tlzDetail, tlzSectionNum, onlyUnique, rptTotal, rptTotalQty,formatCurrency,dollarUSLocale } from '../../functions';

function RptSummaryInformation(props) {
    var summary_tlz = props.data.filter((record,index) => (((tlzDetail(props.data, record.F1034, "F1179") < 3000 || tlzDetail(props.data, record.F1034, "F1179") > 4900) && tlzDetail(props.data, record.F1034, "F1179") !== 1700)) && !(record.F1034 >= 4000 && record.F1034 <= 4004));

    var summary_details = summary_tlz.map((record,index,arr) => tlzDetail(props.data, record.F1034, "F1129"))
        .filter(onlyUnique)
        .sort(function(a,b){
            return tlzSectionNum(props.data, a) - tlzSectionNum(props.data, b);
        })
        .map(function(section,index,arr){
            var section_detail_tmp = summary_tlz.filter((record,index,arr) => tlzDetail(props.data, record.F1034, "F1129") === section)
                .map((record) => record.F1034)
                .filter(onlyUnique)
                .sort(function(a,b){                    
                    return tlzDetail(props.data, a, "F1147") - tlzDetail(props.data, b, "F1147");
                });



            var section_details = section_detail_tmp.map((record,rpt_index) => {
                
                return (
                    <div key={'tss_details_'+index+'_'+rpt_index} className='row'>
                        <div className='col-6 ps-2'>
                            <div className='ml-3 ps-1' data-ltitle={tlzName(props.data, record)} >
                                <span className='d-block value-field'>
                                    {tlzName(props.data, record)}
                                </span>
                            </div>
                        </div>
                        <div className='col-3' data-title={`${dollarUSLocale.format(rptTotalQty(props.data, record).toFixed(2))}`} >
                            <span className='d-block value-field'>
                                {rptTotalQty(props.data, record).toFixed(0) !== 0 ? rptTotalQty(props.data, record).toFixed(0) : "0"}
                            </span>
                        </div>
                        <div className='col-3' data-rtitle={`$${formatCurrency(rptTotal(props.data, record).toFixed(2))}`} >
                            <span className='d-block value-field'>
                                {rptTotal(props.data, record).toFixed(2) !== 0.00 ? `$${formatCurrency(rptTotal(props.data, record).toFixed(2))}` : "$0.00"}
                            </span>
                        </div>
                    </div>
                )
            });
            

            return (
                <div key={'tss_'+index}>
                    <div className='row'>
                        <div className='col-12 ps-1'><b>{section}</b></div>
                    </div>
                    {section_details}
                </div>
                        );
        });


    return (
        <>
            <div className='mt-2 row bg-secondary text-white'>
                <div className='col-6'><b>Description</b></div>
                <div className='col-3'><b>Qty</b></div>
                <div className='col-3'><b>Amt</b></div>
            </div>

            {summary_details}
        </>
    );
}

export default RptSummaryInformation;